<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('MENU.ENTERPRICE.EXPENDITURE.PAYMENT_TYPE') }}
            </h3>
          </div>
        </div>
        <div class="card-body">
          <b-table
            striped
            hover
            id="my-table"
            :filter="filterName"
            :fields="fields"
            :items="allBrands"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      isOpen: false,
      filterName: null,
      sortBy: '',
      sortable: true,
      sortByFormatted: true,
      filterByFormatted: true,
      filterOn: []
    }
  },
  beforeCreate() {
    this.$store.dispatch('getGeneralPaymentTypes')
  },
  computed: {
    fields() {
      return [
        {
          label: '#',
          key: 'id'
        },
        {
          label: this.$t('TABLE_HEADER.NAME'),
          key: 'name'
        }
      ]
    },
    allBrands() {
      const data = this.$store.state.requests.generalPaymentTypes
      console.log('data', data)
      return data
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.ENTERPRICE.TITLE') },
      { title: this.$t('MENU.ENTERPRICE.EXPENDITURE.TITLE') },

      { title: this.$t('MENU.ENTERPRICE.EXPENDITURE.PAYMENT_TYPE') }
    ])
  }
}
</script>
